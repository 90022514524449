<template>
  <div class="goodsDetail_page">
    <div class="container flex justify-content-between"  v-if="gataGoods!=''">
      <div class="info flex">
        <!-- <div class="info_carousel">
          <el-carousel :interval="5000" arrow="always">
            <el-carousel-item
              v-for="(item, index) in goods.images"
              :key="index"
            >
               <el-image style="position:absolute;top:0;bottom:0;left:0;right:0;width:100%;margin:auto;" :src="item" fit="none"></el-image>
            </el-carousel-item>
          </el-carousel>
        </div> -->
        <!-- 图片自适应 -->
        <div class="info_carousel">
          <div style="margin:0 auto;padding-bottom:0;">
            <el-carousel :interval="5000" arrow="always" indicator-position="none" @change="changeCampus">
            <el-carousel-item v-for="(img, index) in goods.images" :key="index">
              <el-image :preview-src-list="goods.images" :class="current===index ? 'campus-active': 'campus' " :src="common.setImgLocation(img)" style="position:absolute;top:0;bottom:0;left:0;right:0;width:100%;margin:auto;" fit="contain"></el-image>
            </el-carousel-item>
            </el-carousel>
          </div>
        </div>
        <div class="info_sth">
          <div class="info_title">{{ goods.name }}</div>
          <div
            class="info_price flex align-items-center justify-content-between"
          >
            <div class="info_p1 flex align-items-center">
              <span style="font-size: 16px;">价格</span>
              <span v-if="goods.start_price!='' && goods.start_price!=null&& goods.start_price!=0">{{ (goods.start_price).toFixed(2) }}</span>
              <span v-else-if="goods.top_price!='' && goods.top_price!=null&& goods.top_price!=0">{{ goods.top_price }}</span>
              <span v-else>{{ (goods.price).toFixed(2) }}</span>
            </div>
          </div>
          <div class="info_package flex justify-content-between">
            <div style="font-size: 16px;">
              店铺地区：{{
                shop.shop_province ? shop.shop_province+'-'+shop.shop_city : "未填写地址"
              }}
            </div>
            
          </div>
          <div class="info_category">商品分类：{{ goodsType }}</div>
          <div class="info_timer">发布时间：{{ goods.created_at }}</div>
          <!-- <div class="info_timer" v-if="goods.cut_time>0">结束时间：{{ countTime(goods.created_at, goods.cut_off_time) }}</div> -->
          <div class="info_timer" v-if="goods.tag_id!=3">结束时间：{{day}}天 {{hour}}时{{min}}分{{second}}秒</div>
          <div class="info_timer" v-if="goods.cut_time>0&&goods.tag_id!=3">起拍价格：{{ first_price }}</div>
          <div class="info_timer" v-if="goods.cut_time>0&&goods.tag_id!=3">加价幅度：{{ goods.each_add }}</div>
          <div class="info_timer" v-if="goods.cut_time>0&&goods.tag_id!=3">参考价：{{ goods.top_price }}</div>
          <div class="info_timer" v-if="goods.cut_time>0&&goods.tag_id!=3">保证金：{{ goods.is_need_bond }}</div>
          <div style="font-size: 16px;">运输方式：{{ postage }}</div>
          <div style="font-size: 16px;" class="info_buy flex align-items-center justify-content-between">
            <div style="font-size: 16px;">
              剩余库存：<span>{{ goods.stock_num }}</span
              >件
            </div>
            <button class="btn_buy" @click="maskShow = !0" v-if="goods.cut_time>0">
              <i class="el-icon-edit"></i><span>立即出价</span>
            </button>
            <button class="btn_buy" @click="maskShow = !0" v-else>
              <i class="el-icon-edit"></i><span>立即购买</span>
            </button>
          </div>
        </div>
      </div>
      <div class="rightInfo">
        <div class="ri_top ellipsis-1">
          {{ shop.store ? shop.store : user.nick_name + "的店铺" }}
        </div>
        <div class="box_item">
          <div v-if="shop.is_windward === 1" class="box_tag">
            <div class="box_icon"><img src="../../static/shop3.png" alt=""></div>
            <div class="text">白虫店铺</div>
          </div>
          <div v-if="shop.is_excellent === 1" class="box_tag">
            <div class="box_icon"><img src="../../static/shop1.png" alt=""></div>
            <div class="text">优质店铺</div>
          </div>
          <div v-if="shop.guarantee === 1" class="box_tag">
            <div class="box_icon"><img src="../../static/danbao.png" alt=""></div>
            <div class="text">平台担保</div>
          </div>
          <div v-if="shop.consignment === 1" class="box_tag">
            <div class="box_icon"><img src="../../static/shop2.png" alt=""></div>
            <div class="text">超级店铺</div>
          </div>
        </div>
        <div class="info_p_cert">
          <div><i class="el-icon-edit"></i>企业认证</div>
          <div><i class="el-icon-edit"></i>消保金</div>
          <div><i class="el-icon-edit"></i>{{ageStr}}</div>
        </div>
        <div class="ri_box_btn flex justify-content-center">
          <button class="ri_btn" @click="navigateTo(`/shopDetail/shopDetail/${shop.id}`)">进入店铺</button>
          <!-- <button class="ri_btn">私信店家</button> -->
        </div>
        <div class="comment">
          <!-- <div class="title">店铺评价</div> -->
          <div class="comment_content">
            <div
              class="comment_item"
              v-for="(item, index) in evaluate_word"
              :key="index"
            >
              {{item.name + '(' + item.evaluate_shops_count + ')' }}
            </div>
          </div>
        </div>
        <!-- <div class="ri_goods">
          <div class="ri_goods_t1">店铺拍品</div>
          <div class="ri_goods_content flex justify-content-between">
            <div
              class="ri_goods_item"
              v-for="(item, index) in shopGoodsList"
              :key="index"
              
            >
            <div v-if="index == 0 || index == 1" style="margin-left: 10px;">
              <div class="ri_goods_item_imgs" @click="btnClick(item.id)">
                <img :src="common.setImgLocation(item.images[0])" alt="" />
              </div>
              <div class="ri_goods_item_title">{{ item.name }}</div>
            </div>
            </div>
          </div>
        </div> -->
      </div>
    </div>
    <div style="display: flex; justify-content: space-between;">
      <div style="width: 70%;">
        <div class="detail"  v-if="gataGoods!=''">
          <div class="datail_title">商品详情</div>
          <div style="padding: 20px; font-size: 20px;">
            <div style="font-size: 18px;" :class="showMore ? '' : 'mores'">{{goods.brief}}</div>
            <div>
              <span style="font-size: 18px;" class="more" @click="showMore = !showMore">{{ showMore ? '收起' : '全文' }}</span>
            </div>
          </div>
          <div class='demo' v-if="goodsVideo!=null&&goodsVideo!=''">
            <video-player class="video-player vjs-custom-skin"
                  ref="videoPlayer"
                  :playsinline="true"
                  :options="playerOptions">
            </video-player>
          </div>
          <div class="box_imgs" v-for="(item,index) in goods.images" :key="index">
            <img :src="common.setImgLocation(item)" alt="">
          </div>
        </div>
        <div v-else style="">
          <div>{{gataMsg}}</div>
        </div>
      </div>
      <div class="form_pai" v-if="shopGoodsList!=''">
        <div class="form_title">店铺拍品</div>
        <div class="pai_lie">
          <div class="" v-for="(item, index) in shopGoodsList" :key="index" style="margin-bottom: 20px;">
            <div  @click="btnClick(item.id)">
              <img class="img" :src="common.setImgLocation(item.images[0])" alt="" />
            </div>
            <div class="name">{{ item.name }}</div>
          </div>
        </div>
      </div>
    </div>
    
    
    <!-- 遮罩层 -->
    <div v-if="maskShow" class="mask" @click="maskShow = !!0">
      <div class="box_notice">
        <div class="title">中蟋网交易市场</div>
        <div class="box_img">
          <img src="../../static/market.png" alt="">
        </div>
        <div class="text">微信扫描识别二维码进入蟋蟀交易市场</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      showMore: !!0,
      evaluate: [],
      evaluate_word: [],
      ageStr: '',
      goodsName: '',
      gataGoods: [],
      gataMsg: '',
      current: 0,
      goodsVideo: '',
      goodsId: null,
      carouselList: [],
      goods: {},
      shop: {},
      user: {},
      shopId: null,
      goodsType: "", //商品分类
      shopGoodsList: [],
      maskShow:!!0,
      playerOptions: {
            //播放速度
            playbackRates: [0.5, 1.0, 1.5, 2.0], 
            //如果true,浏览器准备好时开始回放。
            autoplay: false, 
            // 默认情况下将会消除任何音频。
            muted: false, 
            // 导致视频一结束就重新开始。
            loop: false, 
            // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
            preload: 'auto', 
            language: 'zh-CN',
              // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
            aspectRatio: '16:9',
              // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
            fluid: true,
            sources: [{
                //类型
                type: "video/mp4",
                //url地址
                src: '' 
            }],
            //你的封面地址
            poster: '', 
              //允许覆盖Video.js无法播放媒体源时显示的默认信息。
            notSupportedMessage: '此视频暂无法播放，请稍后再试',
            controlBar: {
                timeDivider: true,
                durationDisplay: true,
                remainingTimeDisplay: false,
                //全屏按钮
                fullscreenToggle: true  
            }
        },
        offerList: [],
        infoStart: '',
        day: '0',
        hour: '00',
        min: '00',
        second: '00',
        starts: '',
        ends: ''
    };
  },
  computed: {
    postage() {
      if (this.goods.postage === 0) {
        return "不包邮";
      } else if (this.goods.postage === 1) {
        return "包邮";
      } else if (this.goods.postage === 2) {
        return "顺丰到付";
      } else if (this.goods.postage === 3) {
        return "顺丰包邮";
      } else if (this.goods.postage === 4) {
        return "普通包邮";
      } else if (this.goods.postage === 5) {
        return "满100元顺丰包邮";
      } else if (this.goods.postage === 6) {
        return "满300元顺丰包邮";
      } else if (this.goods.postage === 7) {
        return "满500元顺丰包邮";
      }
    },
    first_price() {
				if (this.offerList.length > 0) {
					return this.offerList[0].price;
				} else {
					return this.infoStart;
				}
			},
  },
  methods: {
    btnClick(id) {
      // this.navigateTo(`/goodsDetail/goodsDetail/${id}`);
      this.goodsId = id
      this.getData()
      document.title = this.goodsName + '---中蟋网交易市场---专业蟋蟀蛐蛐活体及鸣虫用品用具交易交流平台！'
      // this.$router.go(0);
      // window.reload();
    },
    changeCampus (val) {
	    // console.log(val)
	    this.current = val
	   },
    // 倒计时
    countTime () {
      // 获取当前时间
      let date = new Date()
      let now = date.getTime()
      // 设置截止时间
      let endDate = new Date(this.ends) // this.curStartTime需要倒计时的日期
      let end = endDate.getTime()
      // 时间差
      let leftTime = end - now
      // 定义变量 d,h,m,s保存倒计时的时间
      if (leftTime >= 0) {
        // 天
        this.day = Math.floor(leftTime / 1000 / 60 / 60 / 24)
        // 时
        let h = Math.floor(leftTime / 1000 / 60 / 60 % 24)
        this.hour = h < 10 ? '0' + h : h
        // 分
        let m = Math.floor(leftTime / 1000 / 60 % 60)
        this.min = m < 10 ? '0' + m : m
        // 秒
        let s = Math.floor(leftTime / 1000 % 60)
        this.second = s < 10 ? '0' + s : s
      } else {
        this.day = 0
        this.hour = '00'
        this.min = '00'
        this.second = '00'
      }
      // 等于0的时候不调用
      if (Number(this.hour) === 0 && Number(this.day) === 0 && Number(this.min) === 0 && Number(this.second) === 0) {
        return
      } else {
      // 递归每秒调用countTime方法，显示动态时间效果,
        setTimeout(() => {
          this.countTime()
        }, 1000)
      }
    },
    buyFunc() {
      
    },
    getData() {
      let that = this;
      this.$http.GoodsDetail(`id=${this.goodsId}`).then((res) => {
        console.log(res, "---GoodsDetail");
        console.log(res.data.goods.name);
        this.goodsName = res.data.goods.name;
        this.goods = res.data.goods;
        this.ageStr = res.data.ageStr;
        this.gataGoods = res.data;
        this.gataMsg = res.msg;
        this.starts = res.data.goods.created_at
        this.ends = res.data.goods.cut_off_time
        this.offerList = res.data.offer_price_data;
        this.infoStart = res.data.goods.start_price;
        this.playerOptions.sources[0].src = this.common.setImgLocation(res.data.goods.video)
        this.goodsVideo = res.data.goods.video
        this.evaluate = res.data.goodsComment;
        this.evaluate_word = res.data.evaluate_word;
        this.shop = res.data.shop;
        this.user = res.data.shop.user;
         setTimeout(() => {
          console.log(this.goodsName)
          document.title = this.goodsName + '---中蟋网交易市场---专业蟋蟀蛐蛐活体及鸣虫用品用具交易交流平台！'
        }, 1000)
        this.countTime()
        if (res.data.goods.goods_type.goodstype) {
          that.goodsType =
            res.data.goods.goods_type.name +
            "-" +
            res.data.goods.goods_type.goodstype.name;
        }
        this.$http.ShopGoods(`id=${that.shop.id}&page=1`).then((res) => {
          this.shopGoodsList = res.data.list;
          console.log(res, "---ShopGoods");
        });
        // this.boutiqueGoodsList = res.data.goods;
      });
      console.log(this.shop, "---shop");
    },
  },
  mounted() {
    this.goodsId = this.$route.params.id;
    this.getData();
    
    // console.log(this.$route.params.id,'---this.$route.params.id')
  },
  created () {
   
 },
};
</script>
<style lang="scss" scoped>
.goodsDetail_page {
  padding-top: 60px;
}
.container {
  .info {
    justify-content: space-between;
    width: 895px;
    .info_carousel {
      width: 450px;
      height: 400px;
      padding-top: 50px;
      .el-carousel__item h3 {
        color: #475669;
        font-size: 18px;
        opacity: 0.75;
        line-height: 300px;
        margin: 0;
      }

      .el-carousel__item:nth-child(2n) {
        background-color: #99a9bf;
      }

      .el-carousel__item:nth-child(2n + 1) {
        background-color: #d3dce6;
      }
      .imgs {
        width: 100%;
        height: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
      }
    }
    .info_sth {
      width: 490px;
      height: 300px;
      padding-left: 50px;
      padding-top: 20px;
      .info_title {
        padding: 10px 0;
        font-size: 24px;
      }
      .info_price {
        // height: 70px;
        // padding: 10px;
        .info_p1 {
          span:nth-child(2) {
            font-size: 24px;
            font-weight: 600;
            color: rgba(223, 24, 24, 0.836);
            padding: 0 10px;
          }
        }
      }
      
      .info_package {
        padding-top: 5px;
      }
      .info_category {
        font-size: 16px;
        padding-top: 5px;
      }
      .info_timer {
        font-size: 16px;
        padding-top: 5px;
      }
      .info_buy {
        padding: 10px 10px 0 0;

        span {
          padding: 0 10px;
        }
        .btn_buy {
          padding: 7px 20px;
          border-radius: 5px;
          border: none;
          background-color: #b90000;
          color: #ffffff;
        }
      }
    }
  }
  .rightInfo {
    width: 330px;
    border: 1px solid #ddaa1e;
    .ri_top {
      text-align: center;
      padding: 10px;
      font-size: 22px;
      font-weight: 800;
    }
    .ri_label {
      padding: 2px 10px;
    }
    .ri_box_btn {
      padding: 20px 10px;
      margin-top: 20px;
      border-top: 1px dashed #ccc;
      .ri_btn {
        padding: 10px;
        background-color: #b90000;
        color: #fff;
        border-radius: 5px;
      }
    }
    .ri_goods {
      .ri_goods_t1 {
        font-size: 18px;
        font-weight: 800;
        padding: 10px;
      }
      .ri_goods_content {
        padding: 10px;
        flex-wrap: wrap;
        .ri_goods_item {
          padding-bottom: 5px;
          .ri_goods_item_imgs {
            width: 85px;
            height: 100px;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
              width: 85px;
              height: 100px;
              object-fit: cover;
            }
          }

          .ri_goods_item_title {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            text-align: center;
            margin-top: 30px;
            width: 100px;
          }
        }
      }
    }
  }
}
.detail {
  margin-top: 20px;
  // height: 100px;
  border: 1px solid rgba(223, 24, 24, 0.836);
  .datail_title {
    padding: 10px 20px;
    font-size: 24px;
    background-color: rgba(223, 24, 24, 0.836);
    color: #fff;
  }
  .desc {
    padding: 20px;
    font-size: 20px;
  }
  .box_imgs{
    margin: 20px auto;
    width: 800px;
    padding: 10px;
    border: 1px solid #c0c0c0;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    img {
      width: 100%;
    }
  }
}
.mask {
  width: 100%;
  height: 100%;
  background-color: rgba(104, 104, 104, 0.623);
  position: fixed;
  top: 0;
  right: 0;
  z-index: 999;
  .box_notice {
    width: 330px;
    height: 370px;
    background-color: #fff;
    margin: 200px auto;
    overflow: hidden;
    border-radius: 5px;
    .title{
      font-size: 20px;
      font-weight: 800;
    padding-top: 20px;
      text-align: center;
    }
    .box_img{
      width: 200px;
      height: 200px;
      margin: 20px auto;
      background-color: green;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .text{
      padding-top: 20px;
      text-align: center;
      font-size: 16px;
      // font-weight: 800;
    }
  }
}
.demo{
    width: 800px;
    // height: 338px;
    text-align: center;
    line-height: 100px;
    border: 1px solid transparent;
    border-radius: 4px;
    overflow: hidden;
    background: #fff;
    position: relative;
    box-shadow: 0 1px 1px rgba(0, 0, 0, .2);
    margin: auto;
  }
 
  .demo:hover{
    display: block;
  }
  .campus {
  opacity: 0;
 }

 .campus-active {
  opacity: 1;
 }
 .box_item {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  flex-wrap: wrap;
  justify-content: space-between;
  border-bottom: 1px dashed #ccc;
}
.box_item:nth-last-child(1) {
  border-right: 0px;
}
.box_tag {
    padding-bottom: 10px;
    display: flex;
    align-items: center;
    .text {
      margin-right: 10px;
      margin-left: -5px;
    }
    .box_icon {
      width: 40px;
      height: 30px;
      // background-color: pink;
      font-size: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
      img {
        width: 30px;
        height: 30px;
      }
    }
  }
  .form_pai {
    width: 330px;
    .form_title {
      margin-top: 20px;
      padding: 10px 20px;
      font-size: 24px;
      background-color: rgba(223, 24, 24, 0.836);
      color: #fff;
    }
    .pai_lie {
      border: 1px solid rgba(223, 24, 24, 0.836);
      padding-top: 20px;
      // display: flex;
      // justify-content: space-between;
      align-items: center;
      text-align: center;
      padding-bottom: 5px;
      // border-bottom: 0.5px solid #e8e8e8;
    }
    .img {
        width: 200px;
        height: 200px;
      }
  }
  .info_p_cert {
    padding-top: 15px;
      display: flex;
      justify-content: space-around;
    }
    .comment {
        margin-top: 10px;
        border-top: 1px solid #c0c0c0;
        .title {
          font-size: 16px;
          font-weight: 800;
          padding: 15px 0;
        }
        .comment_content {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          .comment_item {
            margin:10px 10px;
            width: 110px;
            border-radius: 5px;
            // height: 50px;
            padding: 5px 0;
            text-align: center;
            background-color: rgb(189, 59, 80);
            color: #fff;
            margin-bottom: 10px;
          }
        }
      }
      .more {
				color: red;
			}
      .mores {
				color: rgb(0, 0, 0);
			}
</style>